
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      defaultTitle: "Access not authorized"
    };
  },
  computed: {
    homeIcon(): string[] {
      return ["fal", "home"];
    },
    title(): string {
      return this.$route.query.message
        ? (this.$route.query.message as string)
        : this.defaultTitle;
    }
  }
});
